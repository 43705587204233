@-ms-viewport {
  width: device-width;
}
a:link,
a:active,
a:hover,
a:visited {
  text-decoration: none;
}
html,
body {
  height: 100%;
}
body {
  position: relative;
  font-size: 16px;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
.no-padding {
  padding: 0;
}
.div-center {
  display: block;
  margin: 0 auto;
  float: none;
}
#nav .nav-opener {
  display: block;
  width: 20px;
  height: 15px;
  background: none;
  overflow: hidden;
  text-indent: -9999px;
}
#nav .nav-opener:hover {
  opacity: 0.7;
}
#nav .nav-opener:before,
#nav .nav-opener:after,
#nav .nav-opener span {
  background: #ffffff;
  border-radius: 1px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 3px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
#nav .nav-opener:before,
#nav .nav-opener:after {
  content: "";
  top: 6px;
}
#nav .nav-opener:after {
  top: 12px;
}
.nav-active #nav .nav-opener {
  right: 5px;
}
.nav-active #nav .nav-opener span {
  opacity: 0;
}
.nav-active #nav .nav-opener:hover {
  opacity: 0.9;
}
.nav-active #nav .nav-opener:after,
.nav-active #nav .nav-opener:before {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 50%;
  left: 10%;
  right: 10%;
}
.nav-active #nav .nav-opener:after {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.nav-active .nav-drop {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}
.nav-active .page-wrapper {
  margin: 0 -280px 0 280px;
}
.nav-drop > ul {
  text-align: right;
  font-size: 0;
  line-height: 0;
  margin-bottom: 0;
  letter-spacing: -4px;
}
.user-icon {
  margin-right: 10px;
  margin-bottom: -3px;
}
#header .user-icon {
  margin-top: -5px;
  color: white;
}
.nav-drop > ul > li {
  display: inline-block;
  vertical-align: top;
  font-size: 15px;
  line-height: 20px;
  font-weight: bold;
  letter-spacing: 0;
  padding: 10px;
}
.nav-drop > ul > li > a {
  color: white;
}
.nav-drop > ul > li > a * {
  color: white;
}
.nav-drop ul a {
  -webkit-transition: color 0.3s linear;
  transition: color 0.3s linear;
}
.nav-drop ul a:hover {
  text-decoration: none;
  color: #148f77;
}
.resize-active .nav-drop,
.resize-active .nav-drop ul {
  -webkit-transition: none !important;
  transition: none !important;
}
.nav-drop ul li.active a {
  color: white;
}
.admin-menu li:first-of-type a img {
  margin-top: -8px;
}
.admin-footer ul li {
  display: inline-block;
  list-style-type: none;
}
.admin-footer ul {
  display: inline-block;
  float: right;
}
.admin-footer ul li:first-of-type {
  margin-left: 0;
}
.copyright {
  float: left;
}
.admin-footer a:hover {
  text-decoration: none;
  color: white !important;
}
.admin-footer ul li {
  margin-left: 35px;
}
.admin-footer ul li a {
  font-size: 16px;
}
span.down-arrow {
  display: block;
  width: 13px;
  margin-left: 5px;
  margin-top: 3px;
  height: 10px;
  background-image: url('images/down-arrow.png');
  background-repeat: no-repeat;
  background-size: contain;
  float: right;
}
.guide h3 {
  margin-top: 0;
}
.guide-menu {
  background-color: rgba(149, 158, 167, 0.5);
}
.guide-menu ul {
  margin-bottom: 0px;
  padding-left: 0;
}
.guide-menu ul li {
  list-style-type: none;
}
.guide-menu ul li a {
  display: block;
  padding: 15px 20px;
  font-size: 14px;
  font-weight: bold;
  color: white;
  text-transform: uppercase;
}
.guide-menu ul li a:hover {
  background-color: rgba(128, 139, 150, 0.8);
  text-decoration: none;
}
.guide-menu ul li.active a {
  background-color: rgba(128, 139, 150, 0.8);
}
.conv-icon {
  margin-top: 20px;
  margin-right: -20px;
  margin-bottom: 20px;
}
.main-content {
  margin-top: 40px;
}
.main-content h2 {
  margin-top: 0;
}
.nav-drop ul li {
  position: relative;
}
.nav-drop ul li.active span.down-arrow {
  background-image: url('images/down-arrow2.png');
}
header .popup-info:after {
  content: "";
}
header .popup-info {
  display: none;
  height: auto;
  right: 0;
  top: 60px;
  position: absolute;
}
.popup-info {
  margin-bottom: 60px;
  width: 390px;
  position: relative;
  height: 290px;
  z-index: 9999;
  border-radius: 4px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  /* prevents bg color from leaking outside the border */
  background-color: #159f85;
  /* layer fill content + color overlay */
  -moz-box-shadow: 0 3px 14px 1px rgba(0, 0, 0, 0.45);
  /* drop shadow */
  -webkit-box-shadow: 0 3px 14px 1px rgba(0, 0, 0, 0.45);
  /* drop shadow */
  box-shadow: 0 3px 14px 1px rgba(0, 0, 0, 0.45);
  /* drop shadow */
}
.popup-info .col-md-12 {
  padding: 29px 40px;
}
.main-content.signed .container {
  position: relative;
}
.popup-info h3 {
  text-align: left;
  font-size: 18px;
  font-weight: 600;
  margin-top: 3px;
}
.popup-info p {
  font-size: 16px;
  color: white;
  text-align: left;
  font-weight: 400;
}
table {
  max-width: 90%;
}
.popup-info ul li {
  float: left;
}
.popup-info ul li:first-of-type {
  padding-left: 0;
}
.popup-info ul {
  float: left;
  margin-top: 4px;
}
.popup-info a.linkk {
  margin-top: 12px;
  display: block;
}
.popup-info a {
  color: white !important;
  font-size: 17px;
  font-weight: 400;
}
.icon {
  background-image: url("images/icons.png");
  background-repeat: no-repeat;
  display: inline-block;
  font-size: 29px;
}
.icon.fa {
  background-image: none;
}
.icon.next {
  background-position: -222px -68px;
  width: 9px;
  height: 15px;
}
.icon.prev {
  background-position: -208px -68px;
  width: 9px;
  height: 15px;
}
.icon.nike {
  background-position: -237px -67px;
  width: 18px;
  height: 14px;
  margin-left: 14px;
}
.icon.plus {
  background-position: -31px -14px;
  width: 23px;
  height: 23px;
  margin-left: 10px;
  margin-bottom: -5px;
}
.modal-content {
  background-color: #f6e9e8;
}
.popup-info .col-md-2 {
  padding: 0;
}
.popup-info .bottom-options {
  background-color: #1a8c7a;
  overflow: hidden;
  height: 45px;
  border-radius: 4px;
  padding: 0 20px;
}
.popup-info .bottom-options .linkk {
  float: right;
}
.popup-info:after {
  content: url('images/arrow-top2.png');
  position: absolute;
}
.popup-info.one {
  top: 0;
  left: 0;
}
.popup-info.two {
  top: 0;
  right: 0;
}
.popup-info.three {
  top: 110%;
  left: 0;
}
.popup-info.four {
  top: 110%;
  right: 0;
}
.popup-info.five {
  top: 220%;
  left: 0;
}
.popup-info.six {
  top: 220%;
  right: 0;
}
.popup-info.seven {
  top: 350%;
  left: 0;
}
.popup-info.eight {
  top: 350%;
  right: 0;
}
.popup-info.one:after {
  top: 45px;
  left: -38px;
  -ms-transform: rotate(-90deg);
  /* IE 9 */
  -webkit-transform: rotate(-90deg);
  /* Chrome, Safari, Opera */
  transform: rotate(-90deg);
}
.popup-info.three:after {
  bottom: 45px;
  left: -38px;
  -ms-transform: rotate(-90deg);
  /* IE 9 */
  -webkit-transform: rotate(-90deg);
  /* Chrome, Safari, Opera */
  transform: rotate(-90deg);
}
.popup-info.two:after {
  top: 45px;
  right: -38px;
  -ms-transform: rotate(90deg);
  /* IE 9 */
  -webkit-transform: rotate(90deg);
  /* Chrome, Safari, Opera */
  transform: rotate(90deg);
}
.popup-info.four:after {
  bottom: 45px;
  right: -38px;
  -ms-transform: rotate(90deg);
  /* IE 9 */
  -webkit-transform: rotate(90deg);
  /* Chrome, Safari, Opera */
  transform: rotate(90deg);
}
.popup-info.five:after {
  top: -29px;
  right: 45px;
}
.popup-info.six:after {
  top: -29px;
  left: 45px;
}
.popup-info.seven:after {
  bottom: -29px;
  left: 45px;
  -ms-transform: rotate(180deg);
  /* IE 9 */
  -webkit-transform: rotate(180deg);
  /* Chrome, Safari, Opera */
  transform: rotate(180deg);
}
.popup-info.eight:after {
  bottom: -29px;
  right: 45px;
  -ms-transform: rotate(180deg);
  /* IE 9 */
  -webkit-transform: rotate(180deg);
  /* Chrome, Safari, Opera */
  transform: rotate(180deg);
}
.popup-info .arrow-top {
  position: absolute;
  top: -30px;
  right: 45px;
}
.top-banner {
  background-color: white;
}
.top-banner .container .row {
  padding: 50px 0;
}
.top-banner .container .row img {
  margin-right: 20px;
}
.top-banner .container .row h3 {
  margin-top: 0;
  margin-bottom: 5px;
}
.top-banner .container .row p {
  color: #798287;
  font-style: italic;
  margin-top: 0;
}
.main-content .heading h3 {
  font-size: 14px;
  margin: 15px;
}
.main-content .application.header {
  cursor: pointer;
  border-top: 1px solid #bfb8bb;
}
.main-content .application.header h3 {
  font-size: 14px;
  color: #2c3e50;
  font-weight: 400;
  margin: 15px;
}
.main-content .application.header h3.green {
  font-weight: 600;
}
.main-content .application.header h3 a {
  font-weight: 600;
  text-decoration: underline;
}
.provider {
  position: relative;
}
.provider .hint-no-bank-id-old span {
  margin-left: -19px;
}
.provider .mask {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 8;
}
.provider .test-env-message {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  padding: 30px;
  position: absolute;
  background-color: white;
  left: 50%;
  width: 70%;
  top: 25%;
  transform: translate(-50%, -50%);
  z-index: 9;
  text-align: center;
}
.provider .test-env-message .close-dialog {
  float: right;
}
.provider .hideIcon {
  display: none;
}
.provider .gray-out {
  opacity: 0.5;
}
.provider .config {
  display: none;
}
.provider .no-config {
  display: block;
  float: left;
}
.main-content .application-body {
  background-color: rgba(237, 236, 232, 0.57);
}
.main-content .application-body .provider-item {
  border-top: 1px solid #bfb8bb;
}
.main-content .application-body .provider-item .lookup-checkmark {
  color: #222f3f;
}
.main-content .application-body .provider-item .lookup-cross {
  color: #e77070;
}
.main-content .application-body .provider-item .request-group .request-group-content {
  margin-top: 20px;
}
.main-content .application-body .provider-item .request-group .request-group-border {
  width: 100%;
}
.main-content .application-body .provider-item .request-group .request-group-border h3 {
  padding: 15px;
  cursor: pointer;
  border-top: 1px solid #bfb8bb;
  border-bottom: 1px solid #bfb8bb;
  margin-bottom: 0;
}
.main-content .application-body .provider-item .request-group .request-group-border h3 i {
  float: right;
}
.main-content .application-body h3 {
  margin-top: 0;
  margin-bottom: 20px;
}
.main-content .application-body td {
  font-size: 13px;
  color: #2c3e50;
  padding-bottom: 10px;
}
.main-content .application-body td a:link,
.main-content .application-body td a:active,
.main-content .application-body td a:hover,
.main-content .application-body td a:visited {
  color: inherit;
}
.main-content .application-body td:last-of-type {
  padding-left: 30px;
}
.main-content .application-body td:first-of-type {
  font-weight: bold;
  font-size: 13px;
  text-transform: uppercase;
  padding-left: 0;
  vertical-align: top;
}
.main-content .domain-bottom {
  padding: 10px;
  border-top: 1px solid #bfb8bb;
}
.main-content .domain-bottom .btn {
  margin-left: 10px;
  margin-right: 10px;
}
.main-content .domain-bottom a.btn {
  line-height: 2;
}
.main-content .domain-list {
  margin-bottom: 30px;
}
form > .row {
  margin-top: 15px;
  margin-bottom: 15px;
}
form > .row.marginless {
  margin-top: 0px;
  margin-bottom: 0px;
}
form > .row > .col-sm-6:first-of-type,
form > .row > .col-sm-7:first-of-type,
form > .row > .col-sm-8:first-of-type {
  border-right: 1px solid #bfb8bb;
}

form > .row > .col-sm-6.borderless:first-of-type,
form > .row > .col-sm-7.borderless:first-of-type,
form > .row > .col-sm-8.borderless:first-of-type {
  border-right: 0px;
}
.domain-selection .control-label {
  display: block;
}
.domain-selection .new-form-control {
  display: inline-block;
  width: 50%;
  vertical-align: middle;
}
.domain-selection select {
  display: inline-block;
  width: calc(50% - 10px);
  background-color: white;
  border: 0px;
  vertical-align: middle;
  border-radius: 2px;
  height: 40px;
  padding: 0 15px;
}
.domain-selection .ownDomain {
  background-color: white;
  border: 0px;
  width: calc(50% - 10px);
  vertical-align: middle;
  border-radius: 2px;
  height: 40px;
  padding: 0 15px;
  margin-top: 4px;
  margin-left: calc(50% + 4px);
}
form .info-pane-name {
  height: 400px;
}
form .info-pane-name .info-name {
  margin-top: 40px;
}
form .info-pane-name .info-realm {
  position: absolute;
  bottom: 133px;
}
form .info-pane-name .info-callback-urls {
  position: absolute;
  bottom: 0;
}
.icon.edit {
  background-position: -187px -67px;
  width: 15px;
  height: 16px;
  margin-left: 5px;
  margin-bottom: -3px;
}
.icon.delete {
  background-position: -157px -3px;
  width: 40px;
  height: 40px;
  vertical-align: bottom;
  margin-top: -30px;
}
.icon.add {
  background-position: -108px -1px;
  width: 40px;
  height: 40px;
}
.main-content .application-body .second {
  position: relative;
}
.main-content .application-body .second:after {
  content: "";
  height: 1px;
  -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35);
  /* drop shadow */
  -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35);
  /* drop shadow */
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35);
  /* drop shadow */
  display: block;
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  right: 0;
}
.main-content .application-body .second {
  padding: 10px 20px 10px 30px;
  background-color: white;
  border-top: 1px solid #d9dcdf;
}
.main-content .application-body .second h2 {
  margin-bottom: 0;
  margin-top: 7px;
}
.main-content .application-body .third {
  margin-bottom: 20px;
}
.main-content .application-body .third .first {
  background-color: #ecedef;
  border-bottom: 1px solid #bec3c8;
}
.main-content .application-body .third .first:last-of-type {
  border: 0;
}
.main-content.partners .application-list table {
  float: left;
}
.main-content.partners .application-list table.checklist:nth-child(3),
.main-content.partners .profile table.checklist:nth-child(3) {
  clear: both;
}
table.checklist {
  margin-bottom: 20px;
}
table.checklist td {
  padding-left: 0;
}
table.checklist thead td {
  font-size: 13px;
  font-weight: bold;
}
table.checklist tbody td {
  font-weight: normal;
  font-size: 16px;
  text-transform: none;
}
/*input[type="checkbox"]{
    display: none;
}

input[type="checkbox"]+label {
    background: url('images/icons.png') no-repeat;
    height: 16px;
    margin-top: 2px;
    margin-right: 7px;
    background-position: -94px -67px;
    padding-left: 18px;
    padding-bottom: 16px;
    float: left;
    cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
}

input[type="checkbox"]:checked + label + span{
    font-weight: bold;
}

input[type="checkbox"] + label + span{
    font-weight: normal;
}

input[type="checkbox"]:checked + label {
    background: url('images/icons.png') no-repeat;
    height: 16px;
    background-position: -116px -67px;
    padding-left: 18px;
}*/
.main-content.partners .application-list .icon.delete {
  margin-top: 0;
}

.main-content.partners .application-list .button.delete,
.main-content.partners .application-list .button.cancel,
.main-content.partners .application-list .button.save {
  margin-left: 10px;
}
.main-content .search {
  width: 340px;
  margin-bottom: 20px;
  border-radius: 4px;
  display: inline-block;
}
.main-content .search input {
  width: 100%;
  border: 0;
  padding: 11px 15px 11px 50px;
  font-size: 16px;
  color: #2c3e50;
}
.main-content .search .search-icon {
  position: absolute;
  left: 0;
  padding: 12px;
  pointer-events: none;
}
.main-content .search input::-webkit-input-placeholder {
  font-size: 16px;
  color: #abb0b5;
}
.main-content .search input:-moz-placeholder {
  /* Firefox 18- */
  font-size: 16px;
  color: #abb0b5;
}
.main-content .search input::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 16px;
  color: #abb0b5;
}
.main-content .search input:-ms-input-placeholder {
  font-size: 16px;
  color: #abb0b5;
}
.main-content.partners .heading ul li.active a {
  background-color: #808b96;
}
.main-content.partners .heading ul li:nth-child(1) {
  width: 26.63043478%;
}
.main-content.partners .heading ul li:nth-child(2) {
  width: 37.5%;
}
.main-content.partners .heading ul li:nth-child(3) {
  width: 35.86956522%;
}
.search-results h3 {
  font-size: 20px;
  color: #2c3e50;
}
.search {
  background-color: white;
  position: relative;
}
.search .results {
  padding: 5px 20px;
  position: absolute;
  left: 0;
  right: 0;
  top: 42px;
  width: 100%;
  z-index: 99999;
  background-color: white;
}
.search .results ul {
  list-style-type: none;
}
.search .results ul li:first-of-type {
  color: #979da4;
  text-transform: uppercase;
  border-bottom: 1px solid #d5d8dc;
  padding: 5px 0;
}
.search .results ul li a {
  color: #445261;
  font-size: 17px;
}
.search .results ul li {
  font-size: 13px;
}
.search input {
  border-radius: 4px;
}
.search .results {
  -moz-border-bottom-left-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
  /* border radius */
  -moz-border-bottom-right-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
  /* border radius */
  -moz-box-shadow: 0 3px 4px 1px rgba(0, 0, 0, 0.35);
  /* drop shadow */
  -webkit-box-shadow: 0 3px 4px 1px rgba(0, 0, 0, 0.35);
  /* drop shadow */
  box-shadow: 0 3px 4px 1px rgba(0, 0, 0, 0.35);
  /* drop shadow */
}
.search.open {
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  /* border radius */
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  /* prevents bg color from leaking outside the border */
  background-color: #fff;
  /* layer fill content */
  -moz-box-shadow: 0 3px 9px 1px rgba(0, 0, 0, 0.35);
  /* drop shadow */
  -webkit-box-shadow: 0 3px 9px 1px rgba(0, 0, 0, 0.35);
  /* drop shadow */
  box-shadow: 0 3px 9px 1px rgba(0, 0, 0, 0.35);
  /* drop shadow */
}
.close-input {
  z-index: 9999999;
  position: absolute;
  top: 7px;
  right: 17px;
}
.close-input img {
  width: 12px;
}
.main-content .profile {
  background-color: white;
  padding: 20px;
}
.main-content .profile ul {
  list-style: none;
}
.main-content .profile a,
.main-content .profile a:visited,
.main-content .profile a:hover,
.main-content .profile a:active {
  color: #222f3f;
  text-decoration: underline;
}
.main-content.partners .profile .heading {
  margin-bottom: 0;
}
.main-content.partners .profile table {
  float: left;
}
.main-content.partners .profile table thead td:first-of-type {
  text-transform: uppercase;
}
input[type=text].form-control,
input[type=password].form-control,
input[type=number].form-control,
input[type=email].form-control,
input[type=url].form-control,
textarea.form-control,
select.form-control {
  background-color: white;
  border: 0px;
  border-radius: 2px;
  height: 40px;
  padding: 0 15px;
  box-shadow: none !important;
}
div.has-error > input[type=text].form-control {
  border-color: #a94442 !important;
  color: #a94442 !important;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
div.has-success > input[type=text].form-control {
  border-color: #3c763d !important;
  color: #3c763d !important;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
textarea.form-control {
  resize: none;
  height: 100px;
  padding: 10px 15px;
}
.input-group-addon {
  border: 0;
}
.has-feedback label ~ .form-control-feedback {
  top: 30px;
}
.control-label {
  font-weight: normal;
}
.switch input {
  display: none;
}
.switch i {
  display: inline-block;
  cursor: pointer;
  padding-right: 20px;
  transition: all ease 0.2s;
  -webkit-transition: all ease 0.2s;
  border-radius: 30px;
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.5);
  vertical-align: middle;
}
.switch i:before {
  display: block;
  content: '';
  width: 30px;
  height: 30px;
  border-radius: 30px;
  background: white;
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.5);
}
.switch :checked + i {
  padding-right: 0;
  padding-left: 20px;
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.5), inset 0 0 80px $color-primary;
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.5), inset 0 0 80px $color-primary;
}
.switch :disabled + i {
  cursor: default;
}
.main-content.partners .profile label {
  font-size: 13px;
  text-transform: uppercase;
}
.main-content.partners .profile h2 {
  margin-bottom: 25px;
}
.main-content.partners .profile td {
  padding-bottom: 10px;
}
.main-content.partners .profile .col-md-5 {
  margin-bottom: 40px;
}
.main-content.partners .profile .button {
  margin-left: 10px;
}
.main-content.partners .profile {
  position: relative;
}
.main-content.partners .profile .col-md-5:first-of-type .checkbox-label {
  width: 100%;
  margin-bottom: 10px;
}
.main-content.partners .profile .col-md-5:first-of-type label {
  margin-top: 4px;
}
.popup {
  position: fixed;
  padding: 20px;
  background-color: white;
  color: inherit;
  width: 362px;
  -moz-box-shadow: 0 3px 9px 1px rgba(0, 0, 0, 0.35);
  /* drop shadow */
  -webkit-box-shadow: 0 3px 9px 1px rgba(0, 0, 0, 0.35);
  /* drop shadow */
  box-shadow: 0 3px 9px 1px rgba(0, 0, 0, 0.35);
  /* drop shadow */
}
.popup a,
.popup a:visited,
.popup a:active {
  color: inherit;
}
.popup .close {
  position: absolute;
  top: 10px;
  right: 10px;
}
.popup .button {
  margin-left: 0 !important;
}
.popup p {
  font-size: 16px;
  margin-bottom: 5px;
}
.bold {
  font-weight: bold;
}
#header .logo {
  font-size: 28px;
}
#header .logo a,
#header .logo a:visited,
#header .logo a:active {
  text-decoration: none;
  color: white;
}
#header .logo img {
  width: 194px;
  height: auto;
  display: inline-block;
  vertical-align: baseline;
  margin-right: 5px;
  margin-bottom: -1px;
}
@media only screen and (max-width: 768px) {
  #header {
    padding: 4px 0 11px;
  }
  .search-results h3 {
    font-size: 16px;
  }
  .popup-info,
  .dropdown {
    position: relative;
    width: 100%;
    padding: 5px;
    margin-top: 5px;
    top: 0;
    border-radius: 0;
  }
  .popup-info .arrow-top,
  .popup-info .close-modal {
    display: none;
  }
  .sub-menu ul li a {
    width: 100%;
    font-size: 14px;
    text-align: center;
    padding: 10px 0;
  }
  .admin-footer {
    text-align: center;
  }
  .admin-footer .copyright,
  .admin-footer ul {
    float: none;
    padding: 0;
  }
  .sub-menu ul li {
    display: list-item;
    width: 50%;
  }
  .popup-info .close-modal {
    float: right;
  }
  .popup-info .col-md-12 {
    padding: 10px;
  }
  .popup-info ul {
    padding: 10px;
    width: 50px;
    margin-top: 16px;
  }
  .dropdown ul {
    padding: 10px !important;
  }
  .dropdown ul li {
    margin-bottom: 10px !important;
  }
  .bottom-options ul li {
    margin-bottom: 0 !important;
    float: left;
    width: 50%;
  }
  .bottom-options ul {
    padding: 0 !important;
  }
  .popup-info a.linkk {
    float: right;
  }
  .button-green {
    width: 100%;
    padding: 10px 0;
  }
  .popup-info .col-md-2 {
    display: none !important;
  }
  .blog-article .blog-title {
    font-size: 25px;
  }
  .blog-list,
  .blog-article {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .blog-list .single-blog {
    margin-bottom: 30px;
  }
  #header .logo {
    text-align: right;
  }
  #header .logo img {
    width: auto;
    height: 21px;
  }
  #header .row > div {
    position: static;
  }
  .intro-block {
    padding: 51px 0 46px;
  }
  .intro-block .title {
    font-size: 15px;
  }
  .main-content .heading ul li a {
    font-size: 14px;
    padding: 5px 0;
  }
  .icon.plus {
    display: none;
  }
  .main-content .heading {
    padding-bottom: 5px;
    margin-bottom: 10px;
  }
  .main-content .application.header h3 {
    margin-top: 9px;
    font-size: 14px;
  }
  .main-content .application.header,
  .main-content .application-body .first,
  .main-content .application-body .second {
    padding: 10px;
  }
  .icon.delete {
    margin-top: 5px;
  }
  .main-content .application-body td:first-of-type {
    font-size: 11px;
  }
  .main-content .application-body .second h2 {
    margin-top: 9px;
    font-size: 20px;
  }
  .main-content {
    margin-top: 10px;
  }
  .main-content .application-body .third {
    margin-bottom: 10px;
  }
  .main-content .search {
    width: 100%;
  }
  .main-content.partners .heading ul li:nth-child(1),
  .main-content.partners .heading ul li:nth-child(2),
  .main-content.partners .heading ul li:nth-child(3) {
    width: 100% !important;
  }
  .main-content.partners .heading {
    padding-bottom: 0;
  }
  .main-content.partners .application-list table {
    margin-left: 0 !important;
    width: 100%;
    max-width: 100%;
    margin-top: 20px;
  }
  .main-content.partners .application-list .icon.delete,
  .main-content .application-list .button,
  .main-content .application-list .button.cancel {
    float: left;
  }
  .main-content .search {
    margin-bottom: 10px;
  }
  .main-content.partners .application-list .button.delete,
  .main-content.partners .application-list .button.cancel,
  .main-content.partners .application-list .button.save {
    margin-left: 5px;
    margin-top: 10px;
  }
  .main-content .application-body td:last-of-type {
    padding-left: 15px;
  }
  .main-content.partners .application-list table.checklist tbody td {
    font-size: 14px;
  }
  .search .results {
    max-height: 120px;
    overflow-y: scroll;
  }
  .popup.first,
  .popup.second,
  .popup {
    left: 0;
    right: 0;
    width: 100%;
    padding: 5px;
  }
  .main-content .profile {
    padding: 15px;
  }
  .main-content.partners .profile table,
  .main-content.partners .profile table:first-of-type {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .main-content.partners .profile .col-md-6 {
    float: left;
    padding: 0;
    width: 100%;
  }
  .main-content.partners .profile table {
    width: 100%;
  }
  .main-content.partners .profile .button {
    width: 100%;
    margin-bottom: 10px;
  }
  .search .results ul li a {
    font-size: 15px;
  }
}
.small-form-control {
  border: 0;
  background-color: #dde1e2;
  border-radius: 4px;
  height: 20px;
  padding: 0 5px;
}
.error-message .error-details {
  padding-left: 20px;
}
.dynamic-input-view.editable span:hover {
  background-color: #dde1e2;
  transition: background-color 0.3s linear;
}
.login-iframe {
  height: 450px;
  border: none;
}
.tooltip {
  text-transform: none;
  /* override capitalization applied by checkbox labels */
  font-size: 14px;
}
.main-content .application.header .accordion-date {
  margin-right: 50px;
}
.fetching-indicator {
  display: inline-block;
  margin-left: 20px;
}
.input-icon-right {
  position: absolute;
  right: 0;
  padding: 12px;
  pointer-events: none;
  z-index: 4;
}
.btn .fa {
  font-size: 20px;
}
.bottom-right {
  position: absolute;
  right: 10px;
  bottom: 10px;
}
.integration-popup-iframe {
  width: 100%;
  height: 700px;
}
.embedded-users-iframe {
  width: 100%;
  height: 600px;
  border: 0;
}
.inner-popup {
  width: 600px;
  margin: 2% auto;
  position: relative;
  -moz-box-shadow: 0 3px 14px 1px rgba(0, 0, 0, 0.35);
  -webkit-box-shadow: 0 3px 14px 1px rgba(0, 0, 0, 0.35);
  box-shadow: 0 3px 14px 1px rgba(0, 0, 0, 0.35);
}
.inner-popup .popup-header {
  display: none;
  background-color: #2d3e50;
  padding: 15px 30px;
  overflow: hidden;
}
.inner-popup .popup-header h2 {
  color: white;
  display: inline-block;
  float: left;
  margin: 3px 0 0 10px;
  font-size: 27px;
  font-weight: 300;
}
.inner-popup .popup-header span.step-numeration {
  color: white;
  font-size: 27px;
  font-weight: 300;
  float: right;
}
.inner-popup .popup-header img {
  width: 194px;
}
.inner-popup .popup-body {
  padding: 30px;
  overflow: hidden;
}
.inner-popup .popup-body ul {
  padding-left: 18px;
}
.inner-popup .popup-body ul li {
  font-size: 16px;
}
.nemid a {
  color: inherit;
  text-decoration: underline;
}
.experimental {
  color: #e77070;
}
.main-content .feature-box h3 {
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: 0;
  margin-right: 0;
}
