@import "assets/variables.scss";

.application-editor {
  h3 + .tag {
    margin-bottom: 20px;
    margin-top: -5px;
  }

  h3 {
    color: $color-primary;
    font-family: "Roboto Slab";
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    margin-top: 0px;
    margin-bottom: 25px;
  }
}