@import "assets/variables.scss";


.modal-content {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  padding: 25px;
  position: relative;
  border: none;

  &.grey {
    background: $color-background;
  }
  &.center {
    display: flex;
    flex-flow: column;
    align-items: center;
  }

  a {
    text-decoration: underline;
    font-weight: bold;
  }

  .button {
    text-decoration: none;
    font-weight: normal;
  }
}

.modal-header {
  padding: 0;
  border: 0;

  button.close {
    color: $color-primary;
    opacity: 1;
    font-size: 34px;
    position: absolute;
    right: 10px;
    top: 10px;
    line-height: 8px;
    font-weight: bold;
    margin: 0;
  }
}

.modal-title {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  color: $color-primary;
  margin: 0px 0px 20px 0px;
}

.modal-body {
  padding: 0px;
  color: $color-primary;
  font-family: "Raleway";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;

  p, span {
    font-family: "Raleway";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
  }

  input[type=text], input[type=email], input[type=password], input[type=url], select {
    border: 1px solid $grey-4;
  }
}

.modal-footer {
  padding: 0px;
  margin-top: 25px;
  border: 0px;
}

.modal-backdrop {
  background: #000;
}
.modal-backdrop.in {
  opacity: 0.25;
}

@media (min-width: 1000px) {
  .modal-dialog {
      width: 780px;
      margin: 30px auto;
      margin-top: 180px;
  }
}