@import '../../../assets/variables.scss';

.panel {
  border-radius: 6px;
  border: 0;
  color: $color-primary;
  display: flex;
  flex-flow: column;
  flex: 1 1 100%;

  .panel-content {
    padding: 0px 18px 18px 18px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    flex: 1;
  }

  &.center {
    display: flex;
    flex-flow: column;
    align-items: center;
  }

  .image {
    max-width: 100%;
    height: 200px;
    overflow: hidden;
    padding: 18px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h3 {
    font-family: "Roboto Slab";
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 22px;
    padding: 18px;
    color: $color-primary;
  }

  &.panel-TEST {
    h3 {
      color: $color-test;
    }
  }
  &.panel-PRODUCTION {
    h3 {
      color: $color-production;
    }
  }

  &.panel-with-list {
    padding: 0px;

    h3 {
      padding: 18px 36px;
    }
  }

  &.panel-with-list ul {
    display: block;
    margin: 0px 0px 18px 0px;
    list-style-type: none;

    > li {
      padding: 0px 18px;
      &:hover {
        background-color: $warm-light-grey;
      }

      div {
        border-top: 1px solid #EDECE8;
        width: 100%;
      }
    }
    > li, > li a {
      display: block;
      height: 41px;
      display: flex;
      align-items: center;
      color: $blue;
      font-family: "Raleway";
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
    }
  }

  .input-with-button {
    border: 1px solid $grey-3;
  }
}