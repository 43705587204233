@import "../../../../../../assets/variables.scss";


.multi-check-list, .multi-check-list ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.multi-check-list {
  border-bottom: 1px solid $grey-3;
  padding-bottom: 8px;
  margin-bottom: 8px;

  label {
    cursor: pointer;
    font-family: "Raleway";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: $color-primary;
    display: block;
    margin: 0;
  }

  .p-8 {
    padding: 8px;
  }

  > li:first-child {
    border-bottom: 1px solid $grey-3;
    margin-bottom: 8px;
  }

  .criipto-checkbox {
    margin: 8px 12px 8px 0px;
  }

  > li {
    i {
      cursor: pointer;
    }
  }

  li {
    ul {
      display: none;
      padding-left: 36px;
    }

    &.expanded {
      margin-top: 8px;
      border-top: 1px solid $grey-3;
      border-bottom: 1px solid $grey-3;
      margin-bottom: 8px;
      padding-top: 8px;
      padding-bottom: 8px;
      ul {
        display: block;
      }

      &:nth-child(2) {
        border-top: 0px;
      }
    }

    &.expanded + li.expanded {
      border-top: 0px;
    }
  }

  .grey-hover-box {
    margin: 0px -6px;
    padding: 0px 6px;
    border-radius: 3px;

    &:hover {
      background: rgba(0,0,0,0.05);
    }
  }

  .group-item {
    .criipto-checkbox {
      width: 40px;
      height: 40px;
      margin: 0px 4px 0px -8px;

      &:hover {
        border-radius: 50%;
        border: 8px solid rgba(0,0,0,0.05);
        background: rgba(0,0,0,0.05);
      }
    }
  }

  li.single-item, li.idp-item {
    margin: 0px -8px;
    padding: 0px 8px;
    display: flex;
    flex-flow: row;
    align-items: center;
    border-radius: 3px;

    .actions {
      display: none;
    }

    &:hover {
      background: rgba(0,0,0,0.05);
      .actions {
        display: block;
        color: #7B7B78;
        display: flex;
        flex-flow: row;
        margin-left: 8px;

        > * {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          color: #7B7B78;

          &:hover {
            background: rgba(0,0,0,0.1);
          }
        }
      }
    }
  }
}

.multi-check-list-footer {
  text-align: right;
  padding-top: 8px;

  a {
    border-radius: 3px;
    padding: 8px;
    &:hover {
      background: rgba(0,0,0,0.05);
    }
  }
}