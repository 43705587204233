.analytics-stat-box {
  display: flex;
  flex-flow: row no-wrap;
  align-items: center;

  @media(max-width: 576px) {
      flex-flow: column;
  }

  .prefix {
    color: #0a4b85;
    font-family: "Roboto Slab";
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
  }

  .number {
    color: #0a4b85;
    font-family: Raleway;
    font-size: 50px;
    font-weight: 800;
    line-height: 60px;
    letter-spacing: -2.5px;
    margin: 0px 8px;
  }

  .suffix {
    color: #0a4b85;
    font-family: "Roboto Slab";
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
  }
}