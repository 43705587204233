@import "assets/variables.scss";

.onboarding-screen {
  h2 {
    color: $blue;
    font-size: 30px;
    margin-top: 30px;
  }

  .panels {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    margin-top: 30px;
    gap: 24px;
    .panel {
      flex: 1 1 0px;

      .panel-content {
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        height: 100%;
        flex: 1;

        a:not(.button) {
          text-decoration: underline;
        }

        span.code {
          background-color: #f5f5f5;
          border: 1px solid #ccc;
          color: #333;
          padding: 2px;
        }

        .button {
          margin-top: 10px;
        }

        p {
          font-family: "Raleway";
          font-style: normal;
          font-weight: normal;
          font-size: 15;
          line-height: 175%;
        }
      }
    }
  }
}