.date-range-picker-wrapper {
  position: relative;
}

.date-range-picker-overlay {
  position: absolute;
  transform: translateY(10px);
  z-index: 1;

  width: 200px;

  border-radius: 5px;
  border: 1px solid #204C82;
  background-color: #FFF;
  padding: 0px;

  & ul {
    list-style-type: none;
    margin: 0;

    & li {
      display: block;
      padding: 2px 10px;
      height: 30px;
      cursor: pointer;

      color: #204C82;
      font-family: "Roboto Slab";
      font-style: normal;
      font-size: 13px;

      display: flex;
      align-items: center;

      &:hover, &.selected {
        color: #FFF;
        background-color: #204C82;
      }
    }
  }
}

.date-range-calendar-overlay {
  position: absolute;
  transform: translate(210px, 10px);
  z-index: 1;

  border-radius: 5px;
  border: 1px solid #204C82;
  background-color: #FFF;
  padding: 18px 16px 16px 16px;

  table {
    max-width: auto;
  }

  & .DayPicker, & .DayPicker__horizontal, & .CalendarMonthGrid, & .CalendarMonth {
      background: transparent;
      box-shadow: none;
  }

  & .CalendarMonthGrid__horizontal {
      left: 0px;
  }

  & .CalendarMonth {
      padding: 0px !important;
      margin-right: 18px;
  }

  & .CalendarMonth_caption {
      padding-top: 0px;
      padding-bottom: 41px;
  }

  & .DayPicker_weekHeader {
      top: 39px;

      &:last-child {
          margin-left: 18px;
      }
  }

  & .DayPicker_transitionContainer__horizontal {
      margin-bottom: -22px;
  }

  & .DayPicker_weekHeaders__horizontal {
      margin-left: 0px;
  }

  & .DayPickerNavigation_button__horizontalDefault {
      top: -4px;
  }

  & .DayPickerNavigation_leftButton__horizontalDefault {
      left: 0px;
  }

  & .DayPickerNavigation_rightButton__horizontalDefault {
      right: 0px;
  }

  & .CalendarMonth_caption, & .CalendarMonth_caption > * {
      color: #204C82;
      font-family: "Roboto Slab";
      font-weight: 400;
  }

  & .CalendarDay__default {
      color: #807e7b;
  }

  & .CalendarDay__outside, & .CalendarDay__blocked_calendar {
      background: #FFF;
      border: 1px solid #e4e7e7;
      color: #e4e7e7;
  }

  & .CalendarDay__selected_span, .CalendarDay__hovered_span {
      background: #aaa5a1;
      border: 1px solid #918d89;
      color: #FFF;
  }

  & .CalendarDay__selected_start, & .CalendarDay__selected_end {
      background: #204C82;
      border: 1px solid #204C82;
      color: #FFF;
  }
}