@import 'assets/variables.scss';

#footer {
  width: 100%;
  min-height: $footer-height;
  margin-top: $footer-margin;
  overflow: hidden; // Because of graphic
  position: relative;

  &:before {
    content: '';
    border-top: 1px solid $color-production;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;

    @media (min-width: 1000px) {
      width: calc(100% - 60px);
      left: 30px;
    }
  }

  &.environment-TEST:before {
    border-top: 1px solid $color-test;
  }

  &:after {
    content: '';
    opacity: 0.1;
    width: 525px;
    height: 679px;
    background: $grey-3;
    mix-blend-mode: normal;
    border-radius: 30px;
    transform: rotate(42.29deg);
    z-index: 1;

    position: absolute;
    right: 8%;
    top: -53%;
  }

  .container {
    overflow-y: clip;
    overflow-x: visible;
    min-height: $footer-height;
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    position: relative;

    &.left {
      margin: 0;
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  .logo {
    margin-bottom: 15px;
    img {
      height: 30px;
    }
  }

  .links {
    display: flex;
    flex-flow: column;
    z-index: 2;

    @media (min-width: 1000px) {
      flex-flow: row;
    }

    ul {
      list-style-type: none;
      flex: 1;
    }

    a, li {
      color: $blue;
      font-family: "Raleway";
      font-weight: 500;
      font-size: 13px;
      line-height: 215%;
    }

    a:hover {
      color: $light-blue;
    }
  }

  .social {
    display: flex;
    flex-flow: row;
    justify-content: flex-end;

    > * {
      margin-left: 15px;
    }
  }

  .twitter, .linkedin {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .twitter {
    border: 1px solid #55ACE3;
  }

  .linkedin {
    border: 1px solid #2878B7;
  }

  .operation-status-wrapper {
    position: relative;
  }
  .operation-status {
    position: absolute;
    left: -14px;
    top: 9.5px;
  }
}