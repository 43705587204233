@import "../../../assets/variables.scss";

.criipto-checkbox {
  position: relative;
  width: 24px;
  height: 24px;
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;

  input[type="checkbox"] {
    width: 24px;
    height: 24px;
    opacity: 0;
    margin: 0;
    padding: 0;
  }

  .checkbox__control {
    content: ' ';
    cursor: pointer;
    border: 2.5px /* Probably renders as 3px */ solid #6B84A3;
    box-sizing: border-box;
    border-radius: 3px;
    width: 24px;
    height: 24px;
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -12px;
    margin-top: -12px;
    background: transparent;
    display: inline-block;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
  }

  .checkbox__control:hover {
    border: 2.5px solid $color-primary;
  }

  input[type="checkbox"]:checked + .checkbox__control, input[type="checkbox"]:indeterminate + .checkbox__control {
    border: 2.5px solid $color-primary;
    background: $color-primary;
  }
}