@import "assets/variables.scss";

.logs-screen {
  &.container {
    padding-top: $content-padding-top;
  }

  .action-bar {
    display: flex;
    flex-flow: row;
    align-items: center;
    margin-bottom: 10px;
    width: 50%;

    & > * {
      margin-right: 10px;
    }
  }

  .search-input {
    border-radius: 5px;
    background-color: #FFF;
    box-sizing: border-box;

    line-height: 25px;
    color: #555;

    height: 40px;
    padding: 0px 11px;

    display: flex;
    align-items: center;

    & input {
      height: 36px;
      border: 0;
      background: transparent;
    }

    & div {
      margin-right: 11px;
    }
  }

  .log-entry-wrapper {
    background-color: #FFF;
    margin-bottom: 10px;

    & .header {
      display: flex;
      flex-flow: row;
      align-items: center;
      cursor: pointer;
    }

    & .header > * {
      border-right: 4px solid $color-background;
      padding: 5px;
    }

    & .header > *:last-child {
      border-right: none;
    }

    & .header .url {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    & .header .timestamp {
      white-space: nowrap;
    }

    & .details {
      border-top: 4px solid $color-background;
      display: none;
      flex-flow: row;
    }

    & .details.expanded {
      display: flex;
    }

    & .details > * {
      overflow-wrap: anywhere;
      width: 48%;
      padding: 5px;
    }

    & .details .html {
      font-family: monospace;
      white-space: pre;
    }
  }
}