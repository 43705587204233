
.stripe-card-input {
  border: 1px solid #91908D;
  padding: 10px 18px;
  font-size: 16px;
  border-radius: 3px;
  background: #FFFFFF;
  height: 41px;
}

.payment-method-form {
  & .form-group {
    margin: 0;
  }
}