@import "assets/variables.scss";

.production-upgrade-required-modal .modal-content {
  i {
    border: 1px solid $color-test;
    border-radius: 100%;
    height: 23px;
    width: 23px;
    font-size: 12px;
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}