@import "assets/variables.scss";

.domain-creater {
  h3 + .tag {
    margin-bottom: 20px;
    margin-top: -5px;
  }

  h3 {
    color: $color-primary;
    font-family: "Roboto Slab";
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    margin-top: 0px;
    margin-bottom: 25px;
  }

  small.form-text {
    margin-top: 0.5rem;
  }

  .domain-input-group {
    display: flex;
    flex-direction: row;
    max-width: 500px;

    * {
      white-space: nowrap;
      display: flex;
      align-items: center;
    }
    .subdomain-suffix {
      background-color: #e9ecef;
      border: 1px solid #ced4da;
      padding: 0px 14px;
    }

    .tld, .reset {
      background-color: #e9ecef;
      border: 1px solid #ced4da;
      border-left: 0px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      padding: 0px 14px;
      gap: 10px;

      a {
        font-weight: 600;
      }
    }

    .reset {
      border-left: 1px solid #ced4da;
    }
  }
}