@import "assets/variables.scss";

.applications-screen {
  &.container {
    padding-top: $content-padding-top;
  }

  &.with-breadcrumb {
    padding-top: calc($content-padding-top - 10px);
  }

  .breadcrumb {
    padding: 0;
    margin: 0;
    background: 0;
    margin-bottom: 25px;
  }
}
