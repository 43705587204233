@import "bootstrap/dist/css/bootstrap.css";
@import "./typography.scss";
@import "./page.scss";
@import "./dashboard.scss";
@import "./accordion.scss";
@import "./utils.scss";
@import "./style.scss";
@import "./forms.scss";


.code {
  color: #FD575B;
  background: #F2F1ED;

  border: 1px solid $grey-3;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 3px;
}