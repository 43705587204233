@import "assets/variables.scss";

.providers-screen {
  &.container {
    padding-top: $content-padding-top;
  }

  &.with-breadcrumb {
    padding-top: calc($content-padding-top - 10px);
  }

  .breadcrumb {
    padding: 0;
    margin: 0;
    background: 0;
    margin-bottom: 25px;
  }

  h3 {
    color: #204C82;
    font-family: "Roboto Slab";
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    margin-top: 0px;
    margin-bottom: 25px;
  }
  h3 + .tag {
    margin-bottom: 20px;
    margin-top: -5px;
  }
}
