@import "assets/variables.scss";

.analytics-screen.stat-boxes {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 137px;
}

.analytics-screen.chart-container {
  .chart-top {
    margin-left: 55px;
    gap: 15px;
    padding: 10px;
    background-color: #FFF;
    display: flex;
    justify-content: space-between;

    select {
      border: 1px solid #204C82;
      border-radius: 5px;
      font-family: "Roboto Slab";
      font-style: normal;
      font-size: 16px;
      color: #204C82;
    }

    .checkboxes {
      display: flex;
      gap: 15px;
      border: 1px solid #204C82;
      padding: 5px 10px;
      border-radius: 5px;

      label {
        cursor: pointer;
        white-space: nowrap;
        font-family: "Roboto Slab";
        font-style: normal;
        font-size: 16px;
        color: #204C82;
      }

      .criipto-checkbox {
        margin-right: 5px;
      }

      .form-group {
        margin: 0px;
      }
    }
  }

  .chart {
    position: relative;
    flex-basis: 80%;
    min-width: 0;

    @media (min-width: 1600px) {
        flex-basis: 85%;
    }

    height: 500px;

    @media(max-width: 576px) {
        flex-basis: 100%;
    }

    .no-data-banner, .loading-banner {
      color: #dc6a62;
      font-size: 22px;
      padding: 20px;
      position: absolute;
      top: 222.5px;
      left: 50%;
      transform: translate(-50%, -50%);
      margin-left: 27.5px;
      background-color: #FFF;
    }

    & .recharts-label, & .recharts-label > * {
        color: #92908f;
        font-family: Raleway;
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 1.2px;
    }

    & .recharts-cartesian-axis-tick-value, & .recharts-cartesian-axis-tick-value > * {
        color: #92908f;
        font-family: Raleway;
        font-size: 11px;
        font-weight: 500;
    }

    & .recharts-cartesian-axis-tick:first-child, & .recharts-cartesian-axis-tick:last-child {
        display: none;
    }

    & .recharts-cartesian-axis-line {
        display: none;
    }

    & .recharts-cartesian-grid-vertical line:first-child, .recharts-cartesian-grid-vertical line:last-child,
      .recharts-cartesian-grid-horizontal line:first-child, .recharts-cartesian-grid-horizontal line:last-child {
        display: none;
    }
  }

  .dimensions {
    background: white;
    margin-bottom: 55px;
    padding: 10px;
    flex: 1;
    width: 250px;
    height: 544px;

    .list {
      flex-grow: 1;
      overflow-y: auto;
      height: calc(100% - 50px);
    }

    select {
      border: 1px solid #204C82;
      border-radius: 5px;
      width: 100%;
      font-family: "Roboto Slab";
      font-style: normal;
      font-size: 16px;
      color: #204C82;
    }
  }
}
