@import "assets/variables.scss";

.login-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 30px;
  flex: 1;
}

.login-box {
  min-width: 600px;
  margin: 0 auto;
  border-radius: 6px;
  background-color: white;

  @media (min-width: 1000px) {
    width: 600px;
    min-width: auto;
  }

  .alert:last-child {
    margin-bottom: 0px;
  }

  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 65px;
    background: linear-gradient(0deg, #1C487E, #1C487E);
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  h4 {
    font-family: "Roboto Slab";
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: #204C82;
    margin: 0px 0px 20px 0px;
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 30px;
    flex-flow: column;
  }
}