@import "../../../assets/variables.scss";

#notBilledDialog {
  position: fixed;
  z-index: 999999;
  overflow: hidden;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.2);

  .dialog-content {
    position: relative;
    text-align: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
  }

  .close-not-billed-dialog {
    float: right;
  }

  .btn-subscribe {
    display: block;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #333;
    padding: 5px 20px 0px 20px;
    background-color: transparent;
    border: 1px solid rgba(0,0,0,0.2);
    width: auto;

    img {
      margin-top: -4px;
      height: 45px;
    }
  }
}

#header {
  background: $white;
  padding: 5px 12px;
  position: sticky;
  top: 0;
  z-index: 5;

  nav.main-navigation {
    margin-top: 60px;
    ul {
      list-style-type: none;
      display: flex;
      flex-flow: row;
      margin-bottom: -4px;
    }

    li {
      margin-right: 60px;
    }

    a {
      color: $blue;
      font-family: "Roboto Slab";
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      padding-bottom: 12px;
      display: block;
      transition-property: color;
      transition: 0.2s ease-in-out;
    }

    &.environment-PRODUCTION {
      li.active a, a:hover {
        color: $color-production;
      }

      li.active {
        border-bottom: 4px solid $color-production;
      }
    }
    &.environment-TEST {
      li.active a, a:hover {
        color: $color-test;
      }

      li.active {
        border-bottom: 4px solid $color-test;
      }
    }
  }

  .toolbar {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;

    > * {
      flex: 1;
    }
  }

  .tag.tag-environment-TEST, .tag.tag-environment-PRODUCTION {
    font-size: 15px;
    height: 35px;
    width: 200px;
  }

  .environment-label {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
  }

  .tenant-dropdown {
    .dropdown-toggle, .dropdown-toggle > * {
      color: $blue;
      font-size: 16px;
      line-height: 22px;

      span {
        font-family: "Roboto Slab";
        font-style: normal;
        font-weight: normal;
      }
    }


    .menu-option {
      .tenants-name {
        color: $grey-3;
      }
    }

    .dropdown-menu {
      max-height: 500px;
      overflow-y: auto;
    }
  }
}

.dropdown-menu {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 6px;
  border: 1px solid $warm-light-grey;
  background-color: $white;
  left: auto;
  right: 0;

  .menu-option {
    color: $blue;
    font-family: "Raleway";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    height: 28px;
    display: flex;
    align-items: center;
    padding: 0px 16px;

    &.selected, &.selected:hover {
      color: $white;
      background-color: $blue;
    }

    &:hover {
      background-color: $warm-light-grey;
      color: $blue;
    }

    &.manage-users {
      height: auto;
      display: block;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
  .menu-seperator {
    border-top: 1px solid #EDECE8;
    margin: 3px 16px;
    display: block;
  }
}