.chart-legend {
  border-bottom: 1px solid #faf8f5;
  height: 37px;

  .chart-legend-inner {
    margin: 1px 0px;
    height: 35px;
    cursor: pointer;

    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .chart-legend-checkbox {
    width: 17px;
    height: 17px;
    flex: 0 0 17px;
    background-color: #FFF;
    border-radius: 3px;
    margin: 0px 14px;
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;

    & span {
        color: #FFF;
        font-weight: 700;
        font-size: 14px;
    }
  }

  .chart-legend-label {
    min-width: 0; // flexbox overflow fix
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-right: 14px;
  }
}