.chart-tooltip {
  border-radius: 5px;
  border: 2px solid #d4d2cf;
  background-color: #faf8f5;
  padding: 8px;

  font-family: "Raleway";

  .label {
    color: #dc6a62;
    font-family: "Raleway";
    font-size: 14px;
    line-height: 26px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 0;
  }

  .color-box {
    margin: 0;
    margin-right: 5px;
    width: 8px;
    height: 8px;
    flex: 0 0 8px;
    border-radius: 4px;
    margin-top: 1px;
  }

  .dimension {
    display: flex;
    flex-flow: row no-wrap;
    overflow: hidden;
    font-family: "Raleway";
    text-overflow: ellipsis;
    align-items: center;
    margin-top: 3px;

    font-size: 14px;
    line-height: 26px;
  }
}