@import './variables.scss';

body {
  background-color: $color-background;
}
.page-wrapper {
  display: flex;
  flex-flow: column;
  min-height: 100vh;
}

.app-content {
  flex: 1;
  display: flex;
  flex-flow: column;

  .container-full-screen {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.app-content-header {
  background-color: #F2F1ED;
  padding: 20px 30px 20px 30px;

  &.with-tabs {
    padding-bottom: 0px;
  }

  .breadcrumb {
    background-color: transparent;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;

    > *:not(i) {
      font-weight: 500;
      font-size: 16px;
    }
  }

  h1 {
    font-family: "Raleway";
    font-size: 30px;
    font-weight: 600;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    margin: 0;
  }

  .actions {
    gap: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .app-content-tabs {
    margin-top: 20px;
    overflow-x: auto;

    ul {
      display: flex;
      flex-direction: row;
      align-items: center;
      list-style-type: none;
      gap: 20px;
      margin: 0;

      li a {
        color: rgb(22, 19, 25);
        height: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-bottom: 5px solid #F2F1ED;
        min-width: 70px;

        &.active, &:hover {
          border-bottom-color: $blue;
        }
      }
    }
  }
}

.app-tab-content, .app-content-main {
  padding: 20px 30px;
  max-width: 1170px;

  &.full-screen {
    max-width: none;
  }

  .alert {
    max-width: 768px;
    margin: 20px 0px;
  }

  .form-group .alert {
    margin: 0;
  }

  .form-actions {
    margin: 20px 0px;
  }

  p {
    margin: 20px 0px;
    max-width: 768px;

    &:first-child {
      margin-top: 0px;
    }
  }
}

.operation-status {
  width: 9px;
  height: 9px;
  border-radius: 100%;
  background-color: $white;
  display: block;

  &.ok {
    background: #61f95d;
  }
  &.warning {
    background: #f6c452;
  }
  &.danger {
    background: #ed625d;
  }
}

h1, h2, h3 {
  color: $color-primary;
}

a {
  color: $color-primary;
}

* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  -webkit-font-smoothing: antialiased;
  outline: none;
  font-family: 'Raleway';
  font-weight: 400;
}

.default-screen {
  padding-top: $content-padding-top;
}