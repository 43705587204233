@import "assets/variables.scss";

.signup-box {
  background: #FFFFFF;
  border: 1px solid $grey-4;
  border-radius: 4px;

  h3 {
    color: $color-primary;
    font-family: "Roboto Slab";
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    line-height: 50px;
    /* or 125% */

    text-align: center;
    margin-top: 0px;
  }

  .email-form {
    background: $warm-light-grey;
    padding: 20px 35px 35px 35px;

    p {
      font-family: "Raleway";
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 21px;
      text-align: center;
      margin: 20px 0px;
      color: $grey-4;
    }

    .button {
      width: 100%;
      height: 65px;
      font-size: 24px;
    }
  }

  .social {
    font-family: "Raleway";
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    color: $grey-4;
    display: flex;
    flex-flow: column;
    padding: 15px;
    text-align: center;
    text-transform: uppercase;


    button, a.social-button {
      border: 1px solid $grey-4;
      border-radius: 4px;
      background: #FFFFFF;
      display: flex;
      flex-flow: row;
      margin: 0px 15px 15px 15px;
      font-family: "Raleway";
      text-transform: uppercase;
      align-items: center;
      cursor: pointer;

      &:first-of-type {
        margin-top: 15px;
      }

      .icon {
        background: $warm-light-grey;
        padding: 15px;
        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
        img {
          width: 32px;
          height: 32px;
        }
      }
      .text {
        padding-left: 25px;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 26px;
        color: $grey-4;
        text-transform: uppercase;
      }
    }
  }
}