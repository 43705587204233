.sidebar-offset {
  @apply lg:pl-[270px];
  min-height: 100vh;
  display: flex;
  flex-flow: column;
}

.sidebar-hamburger {
  @apply text-primary-900 lg:hidden;
  cursor: pointer;

  & i {
    font-size: 26px;
  }
}

.sidebar-backdrop {
  @apply inset-0 hidden fixed;
  @apply lg:inset-auto lg:block lg:static;

  z-index: 5;
  overscroll-behavior: contain;

  &.show {
    display: block;
  }
}

nav.sidebar {
  @apply bg-primary-700;

  padding: 16px 0px;
  z-index: 6;
  width: 75%;

  display: flex;
  flex-flow: column;
  justify-content: space-between;
  gap: 16px;

  color: #EFEFEF;
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  height: 100vh;

  overscroll-behavior: contain;

  & i {
    color: #EFEFEF;
  }

  & .criipto-logo {
    padding: 0px 19px;
    display: flex;
    justify-content: center;
    flex-shrink: 0;
  }

  & .top, & .bottom, & .scroll {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  & .top {
    min-height: 0px;
    min-width: 0px;
    flex: 1 1 0%;

    & .scroll {
      flex: 1 1 0%;
      overflow-y: auto;
    }
  }

  & .bottom {
    flex-shrink: 0;
  }

  & .environment-switcher {
    padding: 10px 0px;
  }

  & .tenant-dropdown {
    display: flex;
    justify-content: center;
    flex-shrink: 0;

    & .dropdown-toggle {
      display: block;
      padding: 0px 19px;
      color: #EFEFEF;
      font-family: "Roboto Slab";
      font-style: normal;
      font-weight: normal;
      font-size: 22px;
      box-shadow: none;
    }

    & .dropdown-menu {
      max-height: 500px;
      overflow-y: auto;
      overflow-x: hidden;
      left: 0px;
      right: auto;
    }
  }

  & .links {
    margin: 0px;
    & a {
      padding: 0px 19px;
      height: 44px;
      color: #EFEFEF;
      font-family: "Roboto Slab";
      font-style: normal;
      font-weight: normal;
      font-size: 22px;
      cursor: pointer;
      display: flex;
      align-items: center;

      &:hover, &.active {
        @apply text-primary-900;
        background-color: #F9F8F4;

        i {
          @apply text-primary-900;
        }
      }

      & i {
        margin-right: 12px;
        width: 32px;
        display: flex;
        justify-content: center;
      }

      & i.operation-status-icon {
        height: 14px;
        width: 14px;
        margin: 0px 22px 0px 8px;
      }
    }
  }

  & .operation-status-overlay {
    bottom: 10px;
    left: 239px;
    top: auto;
    right: auto;
  }
}

@media (min-width: 1000px) {
  .sidebar-backdrop {
    overscroll-behavior: auto;
  }

  nav.sidebar {
    width: 270px;
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0px;
    height: auto;
  }

  nav.sidebar .dropdown-toggle {
    font-size: 18px;
  }

  nav.sidebar .links a {
    height: 36px;
    font-size: 18px;
  }
}