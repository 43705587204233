@import "assets/variables.scss";

.dashboard-screen .panels-background {
  background-color: #F2F1ED;
  padding: 60px 0px;

  .panels {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    gap: 24px;
    .panel {
      flex: 1 1 0px;

      img {
        height: 30px;
      }

      a {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

.dashboard-screen .stat-boxes {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 137px;
}

.dashboard-screen .video-carousel, .dashboard-screen .video-player {
  background-color: $warm-light-grey;
  padding-top: 45px;
  padding-bottom: 25px;
}

.dashboard-screen .video-carousel {

  display: none;

  .container {
    overflow: hidden;
    position: relative;
  }

  .prev-page, .next-page {
    color: $grey-3;
    position: absolute;
    top: 50%;
    font-size: 24px;
    margin-top: -86px;
    cursor: pointer;

    &.hidden {
      display: none;
    }
  }

  .prev-page {
    left: 0px;
  }

  .next-page {
    right: 0px;
  }

  .scroller {
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;
    width: 100%;
    transition: 0.4s ease-in-out;
  }
  .page {
    display: flex;
    flex-flow: row;
    min-width: 100%;
    justify-content: space-around;

    &.current {
      transform: translate(0, 0);
    }
    &.previous {
      transform: translate(-100%);
    }
  }

  .video {
    display: flex;
    flex-flow: column;
    width: 25%;
    .video-thumbnail {
      position: relative;
      cursor: pointer;
      width: 100%;

      img {
        width: 100%;
      }
      .play-icon {
        position: absolute;
        left: 50%;
        top: 50%;
        fill: $white;
        margin-left: -29px;
        margin-top: -29px;
      }
    }

    h4 {
      font-family: "Roboto Slab";
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 20px;
      color: #000;
      margin-top: 10px;
      margin-bottom: 5px;
    }

    p {
      font-family: "Raleway";
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      margin: 0;
      color: $grey-3;
    }

    &:hover p {
      color: #000;
    }
  }
}

.dashboard-screen .video-player {
  padding-bottom: 45px;
  .container {
    display: flex;
    justify-content: center;
    position: relative;
  }

  .close-video {
    color: $blue;
    position: absolute;
    top: 0px;
    right: 0px;
    font-size: 24px;
    cursor: pointer;
  }

  .prev, .next {
    color: $grey-3;
    position: absolute;
    top: 50%;
    display: flex;
    align-items: center;
    margin-top: -36px;
    cursor: pointer;

    span {
      font-family: "Roboto Slab";
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      width: 158px;
      margin: 10px;
    }

    i {
      font-size: 24px;
    }
  }

  .prev {
    left: 0px;
  }

  .next {
    right: 0px;
    span {
      text-align: right;
    }
  }
}

.dashboard-screen .subscription-box {
  margin-top: 60px;
  h3 strong {
    color: $color-primary;
  }
}