$blue: #204C82;
$blue-muted: #6B84A3;
$light-blue: #4580C9;
$yellow: #EACA87;
$red: #CE7065;
$cyan: #84F4F8;
$grey-1: #F9F8F4;
$warm-light-grey: #F9F8F4;
$grey-2: #EDECE8;
$grey-3: #B8B7B4;
$grey-4: #91908D;
$white: #FFFFFF;

$color-production: $red;
$color-test: $yellow;
$color-primary: $blue;
$color-danger: #a94442;
$color-background: $warm-light-grey;

$footer-height: 270px;
$footer-margin: 60px;
$content-padding-top: 40px;