@import "assets/variables.scss";

.flex {
  display: flex;

  &.horizontal-center {
    align-items: center;
  }

  &.space-between {
    justify-content: space-between;
  }

}

.help-block a {
  color: #737373;
  text-decoration: underline;
}

/* START: Mimick bootstrap 5 flex utility classes */
.d-flex {
  display: flex;
}
.justify-content-start {
  justify-content: start;
}
.justify-content-end {
  justify-content: end;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-between {
  justify-content: space-between;
}
.justify-content-around {
  justify-content: space-around;
}
.justify-content-evenly {
  justify-content: space-evenly;
}
.align-items-start {
  align-items: start;
}
.align-items-end {
  align-items: end;
}
.align-items-center {
  align-items: center;
}
.align-items-baseline {
  align-items: baseline;
}
.align-items-stretch {
  align-items: stretch;
}
.align-self-stretch {
  align-self: stretch;
}
.flex-grow-1 {
  flex-grow: 1;
}
/* END: Mimick bootstrap 5 flex utility classes */

.flex-end {
  justify-content: flex-end;
}

.vertical-center {
  justify-content: center;
}

.clickable {
  cursor: pointer;
}

.text-overflow-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-danger, .text-danger *, .text-danger p {
  color: $color-danger;
}

.text-TEST {
  color: $color-test;
}
.text-PRODUCTION {
  color: $color-production;
}

.text-underline, a.text-underline, a.text-underline:link, a.text-underline:visited, a.text-underline:hover {
  text-decoration: underline;
}
.text-red {
  color: $red;
}

.text-primary {
  color: $color-primary;
}

.pointer {
  cursor: pointer;
}

.svg-red {
  path {
    fill: $red;
  }
}

.hidden-xs {
  @media (max-width: 575.98px) {
    display: none;
  }
}
