.signatures-api-keys {
  table {
    width: 100%;
    max-width: none;
    th {
      font-weight: bold;
    }

    tr td {
      padding-bottom: 5px;
    }

    td.actions > * {
      margin-left: 5px;
    }
  }

  .add-button {
    margin-top: 5px;
  }
}