$height: 41px;

small.form-text {
  margin-top: .25rem;
  display: block;
}

.form-group {
  margin: 0;
  display: block;

  label {
    font-family: "Raleway";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: $color-primary;
    display: block;
    margin-bottom: 7px;
  }

  input[type="text"], input[type="email"], input[type="password"], input[type="url"], select {
    color: $color-primary;
    font-family: "Raleway";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    border-radius: 3px;
    background: $white;
    border: 0;
    height: $height;
    padding: 10px 18px;
    box-sizing: border-box;
    width: 100%;

    &::placeholder {
      color: $grey-4;
    }

    &.has-error {
      color: $color-danger;
    }
  }

  textarea {
    color: $color-primary;
    font-family: "Raleway";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    border-radius: 3px;
    background: $white;
    border: 0;
    width: 100%;
    min-height: 120px;
    padding: 10px 18px;
    box-sizing: border-box;
  }

  &.horizontal {
    display: flex;
    flex-flow: row;
    align-items: center;

    label {
      margin-bottom: 0px;
    }

    .switch {
      margin-right: 10px;
    }
  }

  .button, .button-group {
    margin-top: 7px;
  }

  .input-with-button {
    display: flex;
    flex-flow: row;
    background: $white;
    padding: 2px;

    .button {
      margin: 0;
      height: calc($height - 4px);
    }
    input {
      border-right: none;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      height: calc($height - 4px);
    }
  }

  &.switch-group {
    .form-group {
      margin-top: 8px;
    }
  }
}

.form-group + .form-group, .form-group + .button {
  margin-top: 25px;
}

.form-gap .form-group + .form-group, .form-gap .form-group + .button {
  margin: 0;
}

.form-gap > p {
  margin: 0;
}