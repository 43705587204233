@import "../../../assets/variables.scss";

.button, .button strong, .button span {
  font-family: "Roboto Slab";
  font-style: normal;
  font-size: 16px;
}


.button {
  border-radius: 3px;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 200px;
  box-sizing: border-box;
  padding: 0px 15px;
  border: 0;
  box-shadow: none;
  outline: none;
  font-weight: normal;
  white-space: nowrap;

  strong {
    font-weight: bold;
  }

  &.button-icon {
    min-width: 41px;
  }

  &.button-small {
    min-width: auto;
    height: 30px;
    padding: 5px;
  }

  &.button-auto {
    min-width: auto;
  }

  &.button-primary, &.button-success, &.button-primary:focus, &.button-success:focus, &.button-primary:hover, &.button-success:hover {
    background: $color-primary;
    color: $white;
    border: 0;
    box-shadow: none;
    outline: none;
  }

  &.button-primary[disabled], &.button-success[disabled] {
    opacity: 0.65;
  }
  &.button-secondary, &.button-default {
    background: transparent;
    color: $color-primary;
    border: 1px solid $color-primary;
    box-shadow: none;
    outline: none;
  }
  &.button-danger {
    background-color: $red;
    color: $white;
    border: 0;
    box-shadow: none;
    outline: none;
  }
}

.button-group {
  display: flex;
  flex-flow: row;
  .button {
    margin-right: 9px;
  }
  &.flex-end .button {
    margin-left: 9px;
    margin-right: 0px;
  }
}