@import "../../../assets/variables.scss";

.environment-switcher {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;

  * {
    cursor: pointer;
    color: $grey-3;
    font-size: 20px;
    line-height: 22px;
    font-family: "Roboto Slab";
  }

  svg {
    fill: $grey-3;
    margin-right: 4px;
  }

  .production, .test {
    display: flex;
    align-items: center;

    svg {
      transition-property: fill;
      transition: 0.2s ease-in-out;
    }
    span {
      transition-property: color;
      transition: 0.2s ease-in-out;
    }
  }

  &.environment-PRODUCTION .production {
    svg {
      fill: $color-production;
    }
    span {
      color: $color-production;
    }
  }

  &.environment-TEST .test {
    svg {
      fill: $color-test;
    }
    span {
      color: $color-test;
    }
  }

  .switch {
    margin: 0px 20px;
    position: relative;

    .line {
      border-radius: 14px;
      transform: rotate(-180deg);
      width: 40px;
      height: 16px;
      transition-property: background;
      transition: 0.2s ease-in-out;
    }
    .circle {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
      position: absolute;
      top: -4px;
      transition: 0.2s ease-in-out;
    }
  }

  &.environment-PRODUCTION .switch {
    .line {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), $color-production;
    }
    .circle {
      background: $color-production;
      transform: translate(-6px,0);
    }
  }

  &.environment-TEST .switch {
    .line {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), $color-test;
    }
    .circle {
      background: $color-test;
      transform: translate(calc(40px - 24px + 6px),0);
    }
  }
}