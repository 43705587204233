@import 'assets/variables.scss';
@import 'assets/utils.scss';

.signup-flow {
  padding-top: $content-padding-top;
  width: 100%;
  margin: 0 auto;

  h1, .h1 {
    font-family: "Roboto Slab";
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    line-height: 50px;
    color: #333333;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  h2, .h2 {
    font-family: "Roboto Slab";
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 45px;
    color: #333333;
    margin-top: 0px;
  }

  label {
    color: $grey-4;
    font-weight: bold;
  }

  input[type=text], input[type=email], input[type=password] {
    border: 1px solid $grey-4;
    height: 50px;
  }
}

.signup-screen {
  display: flex;

  > :first-child {
    h2 {
      margin-bottom: 28px;
      margin-top: 15px;
    }
    ul {
      margin: 0;
      list-style-type: none;

      li {
        display: flex;
        flex-flow: row;
        align-items: center;
        margin-bottom: 28px;
        font-family: Raleway;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        color: #333333;

        img {
          margin-right: 10px;
          width: 20px;
          height: 20px;
        }
      }
    }

    .logos {
      margin-top: 50px;
      > div {
        display: flex;
        flex-flow: row;
        justify-content: space-around;
        align-items: center;
      }

      .alka {
        height: 45.93px;
      }
      .telia {
        height: 134.15px;
      }
      .almbrand {
        height: 81px;
      }
      .coloplast {
        height: 48px;
      }
      .eniro {
        height: 32.5px;
      }
    }

  }

  > :last-child {
    margin-left: 50px;
    width: 486px;
  }
}