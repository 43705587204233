.token-signing-keys {
  padding-bottom: 15px;
}

.token-signing-keys table {
  width: 100%;
  max-width: 100%;
}
.token-signing-keys table th {
  text-align: center;
}
.token-signing-keys table td {
  height: 30px;
  vertical-align: middle !important;
  border: 0.5px solid;
  border-left: 0;
  border-right: 0;
  text-align: center;
  padding: 0 !important;
}
.token-signing-keys table td .certName,
.token-signing-keys table td .pkcs7 {
  margin-left: 15px;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.token-signing-keys table td .showCertOnHover,
.token-signing-keys table td .showPkcs7OnHover {
  display: none;
  position: absolute;
  z-index: 1;
  background-color: white;
  padding: 0 5px 0 5px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  margin-top: 14px;
  font-size: 12px;
  left: 0;
  white-space: pre-wrap;
  width: 100%;
}
.token-signing-keys table td .certName:hover .showCertOnHover {
  display: inline-block;
}
.token-signing-keys table td .pkcs7:hover .showPkcs7OnHover {
  display: inline-block;
  height: 300px;
}
.token-signing-keys table .btn-activate {
  border: 0;
  position: relative;
}
.token-signing-keys table .btn-activate .btn {
  padding-left: 6px;
  padding-right: 6px;
  font-size: 12px;
}