@import "./variables.scss";

.accordion-screen {
  padding-top: $content-padding-top;

  h3 + .tag, h3 + environment-tag {
    margin-bottom: 20px;
    margin-top: -5px;
  }
}

.accordion {
  .accordion-header {
    height: 40px;
    display: flex;
    align-items: center;
    h3 {
      font-family: Raleway;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 16px;
      color: $color-primary;
      margin: 0;
    }

    .row {
      width: 100%;
    }
  }
  .accordion-item {
    .accordion-header {
      border-top: 1px solid $grey-3;
      color: $color-primary;
      min-height: 70px;
      height: auto;
      display: flex;
      align-items: center;
      font-family: Raleway;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      color: $color-primary;

      &.large {
        height: 70px;
      }

      .button-group {
        width: 100%;
      }

      .row {
        width: 100%;
        display: flex;
        align-items: center;
        flex-flow: row;

        > * {
          padding-top: 5px;
          padding-bottom: 5px;
        }
      }

      &.muted {
        color: $blue-muted;
      }
    }
    .accordion-body {
      background: transparent;
      padding: 35px 0px;

      &.collapse {
        border-top: 1px solid $grey-3;
      }

      p, strong {
        font-family: "Raleway";
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 17px;
        color: $color-primary;
      }

      .text-danger {
        color: $color-danger;
      }

      strong {
        font-weight: 700;
      }

      p {
        margin-bottom: 15px;
      }

      h3 {
        color: $color-primary;
        font-family: "Roboto Slab";
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        margin-top: 0px;
        margin-bottom: 25px;
      }

      .accordion-section {
        display: flex;
        flex-flow: row;

        > :first-child {
          flex-grow: 1;
          padding-right: 35px;
        }
        > :nth-child(2) {
          padding-left: 35px;
          border-left: 1px solid $grey-3;
          min-width: 41%;
          width: 41%;
        }

        .collapsable :nth-child(2) * {
          display: none;
        }

        .accordion-section-header {
          border-top: 1px solid $grey-3;
          border-bottom: 1px solid $grey-3;
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 70px;

          h3 {
            margin: 0;
          }
        }

        .accordion-section-body {
          display: none;
          padding: 30px 0px;
        }

        &.open .accordion-section-body {
          display: block;
        }

        &.open > :nth-child(2) > * {
          display: block;
        }
      }
      .accordion-section + h3, .accordion-section + .accordion-section {
        margin-top: 50px;
      }

      .accordion-actions {
        margin-top: 40px;
      }
    }
  }
}