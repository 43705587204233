@import "assets/variables.scss";

.form-control-file {
  display: flex;
  flex-flow: row;
  position: relative;
  cursor: pointer;

  input[type="file"] {
    opacity: 0;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
  }

  .button {
    min-width: auto;
    margin: 0px;
  }
}

.form-group.horizontal input[type="checkbox"] {
  margin: 0;
}

.form-group.horizontal {
  input[type="checkbox"] + label {
    margin-left: 5px;
  }

  &.indent {
    margin: 0;
    padding-left: 10px;
  }
}

.form-section {
  display: flex;
  flex-flow: row;

  > :first-child {
    flex-grow: 1;
    padding-right: 35px;
  }
  > .info {
    padding-left: 35px;
    border-left: 1px solid $grey-3;
    min-width: 41%;
    width: 41%;

    > * {
      font-family: "Raleway";
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 17px;
      color: #204C82;
    }

    > strong {
      font-weight: bold;
    }

    p {
      margin-bottom: 15px;
    }

    a {
      text-decoration: underline;
    }
  }

  .form-fields p {
    color: $color-primary;
    font-size: 16px;
    font-family: "Raleway";
    margin-bottom: 25px;
    line-height: 24px;

    a {
      text-decoration: underline;
    }
  }
}

.form-section + h3, .form-section + .form-section {
  margin-top: 50px !important;
}

.form-actions {
  margin-top: 40px;
}