﻿.dashboard {
  .display-none {
    display: none;
  }

  .production-toggle-info {
    background-color: white;
    margin: 0 auto 24px;
  }

  .production-toggle-info {
    cursor: pointer;

    i {
      margin-top: 14px;
      float: right;
    }

    .h4 {
      padding: 12px;
      margin: auto;
      width: 70%;
      white-space: pre-line;
    }
  }

  h3 {
    font-size: 36px;
    font-weight: 300;
    line-height: 1.25;
    margin-top: 20px;
  }



  @media (max-width: 767px) {
    h3 {
      font-size: 35px;
    }
  }

  p {
    font-size: 15px;
  }

  ul, li {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  li a {
    color: #00a9ff;
  }

  section ul li:before {
    content: "";
    height: 8px;
    width: 8px;
    background-color: #2d3e50;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 7px;
  }

  section ul li {
    list-style-type: none;
    padding-left: 20px;
    position: relative;
  }
  /* end log-in & free-trial pages */
  /* ---------------------------------------------------------------------- */
  /* default form */
  .form-group {
    margin-bottom: 12px;
  }

  .form-control {
    height: 40px;
    padding: 8px 16px;
    border: 2px solid white;
    border-radius: 2px;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-size: 16px;
    line-height: 19px;
    transition: border-color 0.2s ease;
    width: calc(100% - 85px);
  }

  .form-control::-webkit-input-placeholder {
    color: rgba(45, 62, 80, 0.5);
    transition: opacity 0.3s ease;
    opacity: 1;
  }

  .form .btn {
    padding: 10px;
    border: none;
    border-radius: 2px;
    font-size: 16px;
    font-weight: 200;
    line-height: normal;
    letter-spacing: .03em;
    transition: background-color 0.2s ease;
  }

  .form .btn-primary {
    background: #2d3e50;
  }

  .row {
    margin: 0;
  }

  .panel-dashboard {
    border: none;
    border-radius: 2px;
    margin: 0 auto 24px;
    box-shadow: none;
    overflow: hidden;
    position: relative;
    z-index: 1;
    color: #2d3e50;
    background: rgba(45, 62, 80, 0.05);
    transition: max-height 0.3s ease;
  }

  .panel-dashboard-white {
    background: white;
  }

  .panel-dashboard-green {
    border-radius: 2px;
    color: white;
    background: #02baa2;
  }

  .panel-dashboard-green .panel-heading:before {
    display: none !important;
  }

  .panel-dashboard *:not(a):not(input):not(button) {
    color: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  .panel-dashboard .panel-heading {
    padding: 15px 20px;
    border: none;
    font-size: 16px;
    line-height: 22px;
    position: relative;
    text-align: center;
  }

  .panel-dashboard .panel-heading:before {
    max-width: 276px;
    height: 1px;
    margin: auto;
    display: block;
    content: '';
    overflow: hidden;
    position: absolute;
    bottom: 10px;
    right: 30px;
    left: 30px;
    background: rgba(45, 62, 80, 0.1);
    transition: opacity 0.2s ease;
  }

  .panel-dashboard .panel-title {
    font-size: 28px;
    line-height: 40px;
  }

  .panel-dashboard .panel-title + p {
    margin-top: 0;
    margin-bottom: 0;
    font-size: inherit;
    line-height: inherit;
    transition: opacity 0.2s ease;
  }

  .panel-dashboard .panel-body {
    padding: 54px;
    font-size: 16px;
    line-height: 22px;
    transition: opacity 0.15s ease 0.15s;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  @media (max-width: 1200px) {
    .panel-dashboard .panel-body {
      padding: 32px;
    }
  }


  .panel-dashboard .panel-body:after {
    content: '';
    display: table;
    clear: both;
  }

  .panel-dashboard ol {
    margin: 0;
  }

  .panel-dashboard ol li {
    margin-bottom: 11px;
    margin-left: 36px;
    position: relative;
  }

  .panel-dashboard ol li:last-child {
    margin-bottom: 0;
  }

  .panel-dashboard ol li:before {
    display: block;
    content: '';
    font-size: 20px;
    font-weight: 200;
    line-height: normal;
    position: absolute;
    top: -4px;
    left: -24px;
  }

  .panel-dashboard ol li:nth-child(1):before {
    content: "1.";
  }

  .panel-dashboard ol li:nth-child(1):before {
    content: "1.";
  }

  .panel-dashboard ol li:nth-child(2):before {
    content: "2.";
  }

  .panel-dashboard ol li:nth-child(3):before {
    content: "3.";
  }

  .panel-dashboard ol li:nth-child(4):before {
    content: "4.";
  }

  .panel-dashboard ol li:nth-child(5):before {
    content: "5.";
  }

  .panel-dashboard ol li:nth-child(6):before {
    content: "6.";
  }

  .panel-dashboard-steps .panel-heading:before {
    bottom: 10px !important;
  }

  .panel-dashboard-support {
    min-height: 0;
  }

  .panel-dashboard .support-icons {
    margin: -10px 0 3px;
    list-style: none;
    text-align: center;
  }

  .panel-dashboard .support-icons li {
    padding: 0 8px;
    display: inline-block;
  }

  .panel-dashboard .support-icons li:before {
    display: none;
  }

  .panel-dashboard-plan {
    position: relative;
    color: #7b463d;
    text-align: center;
  }

  .panel-dashboard-plan .panel-body {
    padding-bottom: 100px;
  }

  .panel-dashboard-plan .panel-body > p:last-of-type {
    margin-bottom: 0;
  }


  .panel-dashboard-plan .plan-title {
    margin-top: -23px;
    font-size: 40px;
    line-height: 56px;
  }

  .panel-dashboard-plan .btn-upgrade {
    width: 200px;
    padding: 14px;
    border-radius: 2px;
    border: none;
    margin: auto;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: .05em;
    font-weight: 200;
    display: block;
    vertical-align: top;
    position: absolute;
    right: 0;
    bottom: 32px;
    left: 0;
    background: #642e25;
    transition: background-color 0.2s ease;
  }

  .panel-dashboard-plan .status {
    padding-bottom: 40px;
  }

  .status .subscribe-btn {
    vertical-align: top;
    background-color: #642e25;
    color: white;
    box-shadow: 0;
    border: 0;
    height: 40px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: -4px;
    border-radius: 0 2px 2px 0;
  }

  .status .subscribe-email {
    vertical-align: top;
    background-color: white;
    border: 0.5px solid;
    border-radius: 2px;
    height: 40px;
    padding: 0 15px;
    box-shadow: none !important;
    border-radius: 2px 0 0 2px;
  }

  .status {
    position: relative;

    .status-gorup {
      width: 70%;
      margin: auto;

      .status-group-headline {
        padding-top: 30px;
        margin-bottom: 0;
        text-align: left;
      }

      .list-status-services {
        td {
          /*border-top: 0 !important;*/
        }
      }

      .status-service {
        .dropdown {
          text-align: left;
        }

        .arrow {
          text-align: right;
        }

        td {
          border: 0 !important;
        }

        tr {
          border-bottom: 0.5px solid rgba(45, 62, 80, 0.1);
        }
      }

      table {
        max-width: 100%;
        width: 100%;
        padding: 0;
        margin: 0;
      }

      .expand-button {
        cursor: pointer;
        text-align: left;
      }
    }


    .subscribe-message {
      font-size: 12px;
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
      white-space: nowrap;
    }

    .confirmation {
      color: #00d867;
    }

    .hasSubscribed {
      color: #f5c700;
    }

    .hasError {
      color: #f82125;
    }
  }

  .status .table-content {
    margin-top: 20px;

    table {
      margin: 0 auto;

      td:nth-child(odd) {
        position: relative;
        padding: 5px 10px;
        border: 0.5px solid;
        text-align: center;
      }

      .dropdown-content {
        display: none;
        position: absolute;
        z-index: 1;
        background-color: white;
        padding: 0 5px 0 5px;
        box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
        bottom: -18px;
        font-size: 12px;
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
      }

      .dropdown:hover .dropdown-content {
        display: inline-block;
      }

      td:nth-child(even) {
        padding: 5px 10px;
        border: 0.5px solid;
      }
    }

    .fa-check {
      color: #00d867;
    }

    .fa-minus-square {
      color: #f5c700;
    }

    .fa-exclamation-triangle {
      color: #f37500;
    }

    .fa-times {
      color: #f82125;
    }

    .fa-wrench {
      color: #0696de;
    }
  }

  .panel-dashboard-total {
    text-align: center;
  }

  .panel-dashboard .total-number {
    margin: -30px 0 0;
    font-size: 136px;
    line-height: 144px;
    font-weight: 200;
    cursor: default;
  }
  /* end verify dashboard */
  /* form support */
  .form-support {
    margin-top: 20px;
    position: relative;
  }

  .form-support .btn-submit {
    width: 87px;
    height: 40px;
    position: absolute;
    bottom: 0;
    right: 0;
  }
  /* end form support */
  /* table spec */
  .table-spec {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 10px;
  }

  .table-spec th, .table-spec td {
    padding: 0 3px;
    font-size: 14px !important;
    font-weight: normal;
    vertical-align: top;
    text-align: center;
  }

  .table-spec th:first-child, .table-spec td:first-child {
    text-align: left;
  }

  .table-spec th:last-child, .table-spec td:last-child {
    text-align: right;
  }

  .table-spec thead tr:first-child td, .table-spec thead tr:first-child th {
    padding-top: 6px;
  }

  .table-spec thead tr:last-child td, .table-spec thead tr:last-child th {
    padding-bottom: 6px;
    border-bottom: 1px solid rgba(45, 62, 80, 0.05);
  }

  .table-spec tbody tr:first-child th, .table-spec tbody tr:first-child td {
    padding-top: 10px;
  }

  .table-spec tbody tr:last-child th, .table-spec tbody tr:last-child td {
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(45, 62, 80, 0.05);
  }

  .table-spec tfoot td {
    padding-top: 12px;
    padding-bottom: 12px;
    border-top: 1px solid rgba(45, 62, 80, 0.5);
    border-bottom: 1px solid rgba(45, 62, 80, 0.5);
    font-weight: 600;
    position: relative;
    z-index: 5;
  }

  .table-spec tfoot td:before {
    content: '';
    display: block;
    overflow: hidden;
    box-sizing: border-box;
    width: 100%;
    height: 1px;
    overflow: hidden;
    position: absolute;
    top: -1px;
    right: 0;
    left: 0;
    background: rgba(45, 62, 80, 0.5);
  }
  /* end table spec */
  /* date selector spec */
  .date-selector {
    margin: -36px 0 13px;
    position: relative;
    text-align: center;
  }

  .date-selector .dropdown {
    margin-left: -30px;
    display: inline-block;
  }

  .date-selector .btn-default {
    padding: 8px 50px 8px 25px;
    border: none;
    border-radius: 20px;
    font-size: 18px;
    line-height: 24px;
    position: relative;
    top: 2px;
    color: #2d3e50;
    background: white;
    transition: color .2s ease, background-color .3s ease;
    outline: none !important;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}
