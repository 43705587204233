@import "assets/variables.scss";

.signup-organization-screen {
  width: 75%;
  margin: 0 auto;

  // input[type=text] {
  //   width: 50%;
  // }

  h1 {
    margin-bottom: 10px;
  }
}