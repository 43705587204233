
.styling-preview-collection-items {
  padding: 0px 15px 15px 15px;
  white-space: nowrap;
  overflow: auto;
}
.styling-preview-item {
  display: inline-block;
  margin-right: 15px;
}

.styling-preview-container .settings {
  margin-top: 15px;
  display: flex;
  align-items: center;
}

.styling-preview-container .settings > * {
  margin-right: 15px;
}

.styling-preview-container .settings .dimensions input {
  width: 75px;
  display: inline-block;
}

.styling-preview-iframe-wrapper iframe {
  border: 1px solid #bfb8bb;
  background-color: #FFF;
}

.styling-preview-item .header {
  clear: both;
  margin: 15px !important;
}

.styling-preview-item .header .expand {
  float: right;
  color: #000;
  cursor: pointer;
}

h3.styling-preview, h2.styling-preview, .main-content .application-body h3.styling-preview {
  font-size: 14px;
  color: #2c3e50;
  font-weight: 600;
  margin: 0;
}
h2.styling-preview {
  font-size: 18px;
}
.styling-preview-collection-header {
  padding: 15px;
  display: flex;
  align-items: center;
}
.styling-preview-collection-header h2 {
  float: left;
  margin: 0 !important;
}
.styling-preview-collection-header select {
  float: left;
  width: auto;
  margin-left: 15px;
}

