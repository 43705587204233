.form-group .vat-identifier {
  display: flex;
  flex-flow: row;
}

.form-group .vat-identifier input.form-control:first-child {
  width: 120px;
  float: left;
  margin-right: 10px;
}
